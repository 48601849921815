import React, { useState } from "react";
import { Card } from "react-bootstrap";
// import { timeSince } from '../../components/utils/utils'
// import { timeSince } from '../../utils/utils'
import { timeSince } from "../utils";
// import ReportContentModal from '../layout/modals/ReportContentModal'
import ReportContentModal from "../layout/ReportContentModal";
import FeedImageModal from "../layout/FeedImageModal";
import FeedChatModal from "../layout/FeedChatModal";
import { ReactComponent as HorizontalMenu } from "../../assets/Horizontal_Menu.svg";
import { ReactComponent as ChatCircle } from "../../assets/Chat_Circle.svg";
import { ReactComponent as ExpandButton } from "../../assets/Expand_Button.svg";
import { extractAndStoreTokenId } from "../../../../utils";
import { v4 as uuid } from "uuid";
// import { Chat } from "@mui/icons-material";

extractAndStoreTokenId();

const FeedCard = ({
  feed,
  userid,
  handleSaveScrollPosition,
  isLoggedIn,
  onSuccessfulReport,
  onSuccessfulBlockUser,
  onSuccessfulPrivacyChange,
  blockReasons,
  reportReasons,
  showPrivate,
}) => {
  const [feedID, setFeedID] = useState(feed.id); // to pass feed id to modal
  const [feedOwnerUid, setFeedOwnerUid] = useState(feed.owner_id);
  const [modalShow, setModalShow] = useState(false);
  const [imageModalShow, setImageModalShow] = useState(false);
  const [chatModalShow, setChatModalShow] = useState(false);

  const [showMore, setShowMore] = useState(false);
  const maxDisplayTextLength = 140;
  // const isMessageTooLong = feed.message.length > 500

  let search = window.location.search;
  let parameter = new URLSearchParams(search);
  const appbarRes = parameter.get("appbar") || false;

  const appbar = appbarRes === "true";

  // let search = window.location.search
  // let parameter = new URLSearchParams(search)
  // const tokenId = parameter.get('tokenId') // composerName = 'Misbah' if tokenId === '123' else 'Anthony' go check FeedScreen.js line 16

  const handleModalGetFeedID = (data) => {
    setFeedID(data.id);
    setFeedOwnerUid(data.owner_id);
    setModalShow(true);
  };

  const handleShowMore = (e) => {
    e.preventDefault();
    setShowMore(true);
  };

  const onHandleCardImage = () => {
    setImageModalShow(true);
  };

  const onHandleCardChat = () => {
    handleSaveScrollPosition();
    setChatModalShow(true);
  };

  const onHandleSuccessfulReport = (contentType, contentId) => {
    onSuccessfulReport && onSuccessfulReport(contentType, contentId);
  };

  const onHandleSuccessfulBlockUser = (contentType, contentId) => {
    onSuccessfulBlockUser && onSuccessfulBlockUser(contentType, contentId);
  };

  const onHandlePrivacyChange = (contentType, contentId) => {
    onSuccessfulPrivacyChange &&
      onSuccessfulPrivacyChange(contentType, contentId);
  };

  /*

 if text.length > 120
    if showMore === true
      display the whole text
    else
      display text + ... + Show more
else
  display the whole text

*/

  return (
    <div key={uuid()}>
      {" "}
      <ReportContentModal
        uid={userid}
        contenttype="feed-post"
        contentid={feedID}
        currentuid={userid}
        contentuid={feedOwnerUid}
        show={modalShow}
        isloggedin={isLoggedIn}
        ispublic={feed.sorter === "~" ? 0 : 1}
        reportReasons={reportReasons}
        blockReasons={blockReasons}
        onHide={() => setModalShow(false)}
        onSuccessfulReport={onHandleSuccessfulReport}
        onSuccessfulBlockUser={onHandleSuccessfulBlockUser}
        onSuccessfulPrivacyChange={onHandlePrivacyChange}
      />
      <FeedImageModal
        show={imageModalShow}
        feedid={feedID}
        image={feed.main_image}
        onHide={() => setImageModalShow(false)}
      />
      <FeedChatModal
        uid={userid}
        show={chatModalShow}
        feedid={feedID}
        url={`/feed/${feed.id}/messages?owner=${feed.uid}&appbar=${appbar}`}
        onHide={() => setChatModalShow(false)}
      />
      <Card key={feed.id} className="gesture-card feed-post">
        <Card.Header className="feed-post-header d-flex flex-column justify-content-start">
          <div className="d-flex justify-content-between full-width align-items-center">
            <p className="post-fromto-info my-1">
              {showPrivate ? (
                feed.sorter === "~" ? (
                  <span
                    class="mr-2 mb-0 badge bg-secondary text-white rounded-pill"
                    style={{ fontSize: "12px" }}
                  >
                    private
                  </span>
                ) : (
                  <span
                    class="mr-2 mb-0 badge bg-danger text-white rounded-pill"
                    style={{ fontSize: "12px" }}
                  >
                    public
                  </span>
                )
              ) : (
                <></>
              )}
              <span className="post-fromto">{feed.from}</span> sent a Gesture to{" "}
              <span className="post-fromto">{feed.to}</span>
            </p>

            <span>
              <button
                className="edit-button"
                onClick={() => handleModalGetFeedID(feed)}
              >
                <HorizontalMenu />
              </button>
            </span>
          </div>
        </Card.Header>

        {feed.main_image ? (
          <Card.Body className="feed-post-body pt-3 pb-0">
            <div
              className="feed-post-main-image-container"
              onClick={() => onHandleCardImage()}
            >
              <img
                src={feed.main_image}
                alt={`A Gesture sent from ${feed.from} to ${feed.to}`}
                className="feed-post-main-image"
              />
              <ExpandButton className="expand-button" />
            </div>

            {feed.message?.length > 0 ? (
              <p className="mt-3 feed-post-message">
                {feed.message?.length > maxDisplayTextLength ? (
                  showMore ? (
                    <>
                      {feed.message?.split("\n").map((item, index) => {
                        return (
                          <>
                            <span key={index}>
                              {item}
                              <br />
                            </span>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <span>
                        {feed.message?.substring(0, maxDisplayTextLength) +
                          "..."}
                      </span>
                      <small
                        key={uuid()}
                        onClick={handleShowMore}
                        style={{
                          fontStyle: "italic",
                          cursor: "pointer",
                          color: "#aaa",
                        }}
                      >
                        {" "}
                        Show more
                      </small>
                    </>
                  )
                ) : (
                  <>
                    {feed.message?.split("\n").map((item, index) => {
                      return (
                        <>
                          <span key={index}>{item}</span>
                        </>
                      );
                    })}
                  </>
                )}
              </p>
            ) : (
              <></>
            )}

            <div
              className={
                showMore ? "message-doodle-showMore" : "message-doodles"
              }
            >
              <div className="post-sub-image">
                <img
                  src={feed.sub_image}
                  alt={"A doodle created by " + feed.from}
                  className="feed-post-sub-image"
                />
              </div>
            </div>
          </Card.Body>
        ) : (
          <Card.Body className="feed-post-body">
            <div
              className={
                showMore ? "message-doodle-showMore" : "message-doodles"
              }
            >
              {!feed?.message.length ? (
                <></>
              ) : (
                <div className="post-message text-center">
                  <i className="fas fa-quote-left" />
                  <p className="m-0">
                    {feed.message.length > maxDisplayTextLength ? (
                      showMore ? (
                        <em>
                          {feed.message.split("\n").map((item, index) => {
                            return (
                              <>
                                <span key={index}>
                                  {item}
                                  <br />
                                </span>
                              </>
                            );
                          })}
                        </em>
                      ) : (
                        <>
                          <em>
                            <span>
                              {feed.message.substring(0, maxDisplayTextLength) +
                                "..."}
                            </span>
                          </em>
                          <small
                            onClick={handleShowMore}
                            style={{
                              fontStyle: "italic",
                              cursor: "pointer",
                              color: "#aaa",
                            }}
                          >
                            {" "}
                            Show more
                          </small>
                        </>
                      )
                    ) : (
                      <em>
                        {feed.message.split("\n").map((item, index) => {
                          return (
                            <>
                              <span key={index}>
                                {item}
                                <br />
                              </span>
                            </>
                          );
                        })}
                      </em>
                    )}
                  </p>
                  <i className="fas fa-quote-right" />
                </div>
              )}

              <div className="post-sub-image">
                <img
                  src={feed.sub_image}
                  alt={"A doodle created by " + feed.from}
                  className="feed-post-sub-image"
                />
              </div>
            </div>
          </Card.Body>
        )}

        <Card.Footer className="feed-post-footer">
          <div className="post-chatroom-btn">
            <ChatCircle
              className="post-chatroom-button"
              onClick={onHandleCardChat}
            />
          </div>
          <div className="spacer"></div>
          <div className="post-timestamp">
            <small>{timeSince(feed.creationTime)}</small>{" "}
          </div>
          {/* {!isLoggedIn && <small>You must be logged in to comment</small>} */}
        </Card.Footer>
      </Card>
    </div>
  );
};

export default FeedCard;
