import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import FeedCard from "./FeedCard";
import NavbarFeed from "./NavbarFeed";
// import Floater from "../layout/Floater";
import {
  fetchReportReasonsAPICall,
  fetchBlockReasonsAPICall,
  fetchFeedsAPICall,
  fetchOlderFeedsAPICall,
  fetchPrivateFeedsAPICall,
  getUserIdAndNameAPICall,
} from "../../env/APImanager";
import { v4 as uuid } from "uuid";

import "./FeedScreen.css";
import { toast, ToastContainer } from "react-toastify";

const FeedScreen = () => {
  const [feeds, setFeeds] = useState([]);
  const [loadingFeeds, setLoadingFeeds] = useState(false);
  const [loadingMoreFeeds, setLoadingMoreFeeds] = useState(false);
  const [reportReasons, setReportReasons] = useState([]);
  const [blockReasons, setBlockReasons] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [uid, setUid] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(0);

  let search = window.location.search;
  let parameter = new URLSearchParams(search);

  const appbar = parameter.get("appbar") || false;

  const os = parameter.get("os") || null;

  // const appbarDisplay = appbar === "true";

  // const forceAppbar = parameter.get("forceAppbar") || false;

  const [showPrivate, setShowPrivate] = useState(false);

  const feedStorageItems = JSON.parse(sessionStorage.getItem("feedStorage"));

  useEffect(() => {
    // window.scrollTo(0, 0)

    getUserIdAndName();

    if (os === "android" && isLoggedIn) {
      fetchPrivateFeeds();
    } else {
      fetchFeeds();
    }

    fetchReportReasons();
    fetchBlockReasons();
  }, []);

  useEffect(() => {
    handleGetScrollPosition();
  }, [feeds]);

  const fetchReportReasons = async () => {
    try {
      const reportReasonsRes = await fetchReportReasonsAPICall();

      setReportReasons(reportReasonsRes);
    } catch (error) {
      console.warn("[FeedScreen] fetchReportReasons error: ", error);
    }
  };

  const fetchBlockReasons = async () => {
    try {
      const blockReasonsRes = await fetchBlockReasonsAPICall();

      setBlockReasons(blockReasonsRes);
    } catch (error) {
      console.warn("[FeedScreen] fetchBlockReasons error: ", error);
    }
  };

  const fetchFeeds = async () => {
    setLoadingFeeds(true);
    setFeeds([]);
    let currentFeeds = [];

    if (
      feedStorageItems &&
      feedStorageItems.length > 0 &&
      feedStorageItems[0] === feeds[0] // if there is no new feed element in feeds state arr
    ) {
      currentFeeds = feedStorageItems;
    } else {
      currentFeeds = await fetchFeedsAPICall();
    }

    setFeeds(currentFeeds);
    sessionStorage.setItem("feedStorage", JSON.stringify(currentFeeds));

    setLoadingFeeds(false);
    setShowPrivate(false);
    setHasMore(currentFeeds.length > 0);
  };

  const fetchPrivateFeeds = async () => {
    setLoadingFeeds(true);
    setFeeds([]);

    const currentFeeds = await fetchPrivateFeedsAPICall();

    setFeeds(currentFeeds);

    setLoadingFeeds(false);
    setShowPrivate(true);
    setHasMore(currentFeeds.length > 0);
  };

  const getUserIdAndName = async () => {
    try {
      const userRes = await getUserIdAndNameAPICall();

      setUid(userRes.data.data.uid);
      setIsLoggedIn(1);
    } catch (error) {
      console.warn("[FeedScreen] getUserIdAndName error: ", error);
    }
  };

  const getOlderFeeds = async () => {
    setLoadingMoreFeeds(true);

    sessionStorage.setItem("scrollPosition", window.pageYOffset);

    // const paginateKey = (feeds[feeds.length - 1].sorter + 1).toString()
    const paginateKey = (
      (feeds[feeds.length - 1]?.creationTime - 1) *
      -1
    ).toString();

    const olderFeeds = await fetchOlderFeedsAPICall(paginateKey);

    let newFeeds = [...feeds, ...olderFeeds];

    setFeeds(newFeeds);

    sessionStorage.setItem("feedStorage", JSON.stringify(newFeeds));

    setLoadingMoreFeeds(false);

    if (olderFeeds.length === 0) setHasMore(false);
  };

  const handleSaveScrollPosition = (e) => {
    sessionStorage.setItem("scrollPosition", window.pageYOffset);
  };

  const handleGetScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");

    if (typeof parseInt(scrollPosition) === "number") {
      const parsedPosition = parseInt(scrollPosition);
      window.scrollTo(0, parsedPosition);
    }
  };

  // On a successful report remove the given item from the list
  const onSuccessfulReport = (contentType, contentId) => {
    console.log("onSuccessfulReport", contentType, contentId);
    const newFeeds = feeds.filter((feed) => feed.id !== contentId);
    setFeeds(newFeeds);
    toast.success("Reported successfully");
  };

  // On a successful block refetch the feed and toast that the user was blocked
  const onSuccessfulBlockUser = () => {
    fetchFeeds();
    toast.success(
      "We've blocked this user. You'll no longer see their content."
    );
  };

  const onSuccessfulPrivacyChange = (contentType, contentId) => {
    // Find the item in the list and update the privacy by flipping the value
    const newFeeds = feeds.map((feed) => {
      if (feed.id === contentId) {
        return {
          ...feed,
          sorter: feed.sorter === "~" ? feed.creationTime * -1 : "~",
        };
      }
      return feed;
    });
    setFeeds(newFeeds);
    toast.success("Privacy updated successfully");
  };

  return (
    <section className="feedsscreen-container">
      <NavbarFeed
        appbar={appbar}
        fetchFeeds={fetchFeeds}
        fetchPrivateFeeds={fetchPrivateFeeds}
        isLoggedIn={isLoggedIn}
        showPrivate={showPrivate}
      />

      <div>
        <section>
          <ToastContainer />
          {loadingFeeds && (
            <div className="feeds-loading">
              <ClipLoader size={50} color="#2B08A0" />
            </div>
          )}

          {feeds
            .sort((a, b) => {
              // sorts the array by creationTime
              // the latest feed displays on top
              return b.creationTime - a.creationTime;
            })
            .map((feed, index) => (
              <>
                <FeedCard
                  key={uuid()}
                  feed={feed}
                  isLoggedIn={isLoggedIn}
                  userid={uid}
                  showPrivate={showPrivate}
                  reportReasons={reportReasons}
                  blockReasons={blockReasons}
                  handleSaveScrollPosition={handleSaveScrollPosition}
                  onSuccessfulReport={onSuccessfulReport}
                  onSuccessfulBlockUser={onSuccessfulBlockUser}
                  onSuccessfulPrivacyChange={onSuccessfulPrivacyChange}
                />
                <hr style={{ margin: "16px 0px 0px" }} />
              </>
            ))}

          {!loadingFeeds && hasMore && !showPrivate ? (
            <button
              className="load-more-btn load-more-feeds mt-3"
              onClick={getOlderFeeds}
            >
              {loadingMoreFeeds ? (
                <div className="more-feeds-loading">
                  <ClipLoader size={18} color="#fff" />
                </div>
              ) : (
                "Load more"
              )}
            </button>
          ) : (
            <div className="no-more-feeds mt-3 text-center">
              <p>No more items to load</p>
            </div>
          )}
        </section>
      </div>
    </section>
  );
};

export default FeedScreen;
